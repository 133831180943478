.main-content {}

.page_title {
    padding-top: 4rem;
    margin: 2rem;
    text-align: start;
    color: var(--title-color);
    font-weight: bold;
    font-size: x-large;
}

.page_description {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
}