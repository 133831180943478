.test_body {
  margin: 2rem;
  margin-top: 0;
}

.test_instances_table {
  /* border-collapse: collapse; */
  width: -webkit-fill-available;

  td {
    /* border: 0.05rem solid; */
    padding: 0.5rem;
    vertical-align: top;
  }
}

.test_instance_title {
  color: var(--title-color);
  font-weight: 600;
}

.test_instance_components {
  overflow-x: auto;
}

.test_instance_components_table {
  width: -webkit-fill-available;

  :first-child td {
    padding-top: 0;
  }

  td {
    vertical-align: top;
  }
}

.test_instance_description {}